import { createStore, applyMiddleware } from "redux";
import { reducers, AppState, Actions } from "./reducers";
import thunk, { ThunkAction } from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

let middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
    // const logger = require("redux-logger").default;
    middleware = [...middleware]; //logger
}

export type ThunkResult<R> = ThunkAction<R, AppState, undefined, Actions>;

const persistConfig = {
    key: "root",
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, applyMiddleware(...middleware));
export const persistor = persistStore(store);
