import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { FullWidthLinearLoader } from "../../components/LinearLoader";
import Promotion from "../../types/Promotion";

const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 10rem;

    &:empty {
        display: none;
    }
`;

type Props = {
    array: Promotion[];
    loaded: boolean;
    loading: boolean;
    failureMessage: string;
};

const LoadingStatus: FunctionComponent<Props> = ({
    loaded,
    loading,
    array,
    failureMessage
}) => {
    if (loading === true || (array.length === 0 && loaded === false)) {
        return (
            <StyledContainer>
                <FullWidthLinearLoader width={250} text="Loading..." />
            </StyledContainer>
        );
    }
    return (
        <StyledContainer>
            {loaded === true && array.length === 0 && loading === false
                ? failureMessage
                : null}
        </StyledContainer>
    );
};

export default LoadingStatus;
