import Filter from "../..";
import Industry from "../../../../../../types/Industry";
import React from "react";
import Retailer from "../../../../../../types/Retailer";
import _ from "lodash";
import { AppState } from "../../../../../../reducers";
import { selectRetailers } from "../../../../../../reducers/access/actions";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const StyledRetailers = styled(Grid)`
    @media (max-width: 950px) {
        margin-top: 1.5rem;
    }

    @media (max-width: 600px) {
        margin-top: 1.5rem;
        width: 100%;
        max-width: 100%;

        > div,
        input {
            width: 100%;
            max-width: 100%;
        }
    }
`;

const allRecord = {
    name: "all",
    IndustryId: 0,
};

const mergeWithIndustries = (industries: Industry[], retailers: Retailer[]) => {
    const findIndustry = (id: number) => {
        const industry = industries.find((i: Industry) => i.id === id);
        return industry?.name || "";
    };

    const withIndustries = retailers.map((r) => ({
        ...r,
        IndustryName: findIndustry(r.IndustryId || 0),
    }));

    const result = _.sortBy(withIndustries, "IndustryName");

    return result;
};

const Retailers = () => {
    const dispatch = useDispatch();
    const { retailers: rawRetailers, selectedRetailers } = useSelector(
        (state: AppState) => state.access
    );
    const { industries } = useSelector((state: AppState) => state.industries);

    const retailers = [allRecord, ...rawRetailers];
    const selected = mergeWithIndustries(industries, selectedRetailers);
    const options = mergeWithIndustries(industries, retailers);

    const handleChange = (e: any, value: any) => {
        dispatch(selectRetailers(value));
    };

    const onLoad = () => {
        dispatch(selectRetailers([allRecord]));
    };

    return (
        <StyledRetailers item xs={12} sm={4} md={3}>
            <Filter
                onLoad={onLoad}
                selected={selected}
                options={options}
                handleChange={handleChange}
                label="Retailers"
                groupBy="IndustryName"
            />
        </StyledRetailers>
    );
};

export default Retailers;
