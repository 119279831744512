export const GET_PROMOTIONS_START = "GET_PROMOTIONS_START";
export const GET_PROMOTIONS = "GET_PROMOTIONS";
export const GET_PROMOTIONS_ERROR = "GET_PROMOTIONS_ERROR";

export const SELECT_FILTERS_TYPE = "SELECT_FILTERS_TYPE";
export const SELECT_VIEW_TYPE = "SELECT_VIEW_TYPE";
export const SELECT_GROUPED_RETAILERS = "SELECT_GROUPED_RETAILERS";

export const RESET_PROMOTIONS = "RESET_PROMOTIONS";
export const SET_BRAND_FILTER = "SET_BRAND_FILTER";

export const SET_ONLY_NEW = "SET_ONLY_NEW";

export const GET_PROMOTION_DETAILS = "GET_PROMOTION_DETAILS";
export const GET_PROMOTION_DETAILS_START = "GET_PROMOTION_DETAILS_START";
export const GET_PROMOTION_DETAILS_ERROR = "GET_PROMOTION_DETAILS_ERROR";
