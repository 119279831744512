import ReactGA from "react-ga";
import axios from "axios";
import { Auth } from "aws-amplify";
import { LOGIN_START, LOGIN, LOGIN_ERROR, LOGOUT, GET_USER, RESET_LOGIN_LOADER } from "./constants";
import { ThunkResult } from "../../store";
import { resetPromotions } from "../promotions/actions";

type loginStart = { type: typeof LOGIN_START };

type login = {
    type: typeof LOGIN;
    payload: {
        token: string;
    };
};

type loginError = {
    type: typeof LOGIN_ERROR;
    payload: {
        error: string;
    };
};

export function login(
    email: string,
    password: string,
    newPassword: string
): ThunkResult<void> {
    return async dispatch => {
        dispatch(resetPromotions());
        dispatch<loginStart>({ type: LOGIN_START });

        try {
            const user = await Auth.signIn(email, password);

            let { signInUserSession, challengeName } = user;

            if (!!challengeName) {
                if (challengeName === "NEW_PASSWORD_REQUIRED") {
                    if (!!newPassword) {
                        const updatedUser = await Auth.completeNewPassword(
                            user,
                            newPassword,
                            {
                                email
                            }
                        );

                        signInUserSession = updatedUser.signInUserSession;
                    } else {
                        dispatch<loginError>({
                            type: LOGIN_ERROR,
                            payload: { error: challengeName }
                        });
                        return;
                    }
                }
            }

            dispatch<login>({
                type: LOGIN,
                payload: { token: signInUserSession?.accessToken?.jwtToken }
            });
            dispatch(getUser());
        } catch (error) {
            dispatch<loginError>({
                type: LOGIN_ERROR,
                payload: { error: error.message }
            });
        }
    };
}

type logout = {
    type: typeof LOGOUT;
};

export function logout(): ThunkResult<void> {
    return async dispatch => {
        await Auth.signOut();
        dispatch<logout>({ type: LOGOUT });
        dispatch(resetPromotions());
    };
}

type resetLoginLoader = {
    type: typeof RESET_LOGIN_LOADER;
};

export function resetLoginLoader(): ThunkResult<void> {
    return async dispatch => {
        dispatch<resetLoginLoader>({ type: RESET_LOGIN_LOADER });
    };
}

type ServerResponse = {
    data: SuccessResponse | FailureResponse;
};

type SuccessResponse = {
    status: "SUCCESS";
    userId: number;
    company: {
        id: number;
        name: string;
    };
};

type FailureResponse = {
    status: "ERROR";
    message: string;
};

type getUser = {
    type: typeof GET_USER;
    payload: {
        userId: number;
        companyId: number;
    };
};

export function getUser(): ThunkResult<void> {
    return async (dispatch, getState) => {
        const { token, loggedIn } = getState().authentication;

        if (!loggedIn) {
            return;
        }

        try {
            const response: ServerResponse = await axios({
                url: process.env.REACT_APP_API + "/api/user",
                method: "POST",
                headers: {
                    accesstoken: token
                }
            });

            const { data } = response;

            if (data.status === "SUCCESS") {
                const { userId, company: { id: companyId, name: companyName } } = data;
                ReactGA.set({ userId });

                ReactGA.event({
                    category: "User",
                    action: "Company Login",
                    label: companyName,
                });

                dispatch<getUser>({
                    type: GET_USER,
                    payload: { userId, companyId }
                });
            }
        } catch (error) {
            console.log(error, "error");
        }
    };
}

export type authenticationActions =
    | loginStart
    | login
    | loginError
    | logout
    | getUser
    | resetLoginLoader;
