import axios from "axios";
import {
  GET_ACCESS_START,
  GET_ACCESS,
  GET_ACCESS_ERROR,
  SELECT_RETAILERS,
  SELECT_CATEGORIES,
} from "./constants";
import Category from "../../types/Category";
import Retailer from "../../types/Retailer";
import _ from "lodash";
import { ThunkResult } from "../../store";

type ServerResponse = {
  data: SuccessResponse | FailureResponse;
};

type SuccessResponse = {
  status: "SUCCESS";
  retailers: Retailer[];
  categories: Category[];
};

type FailureResponse = {
  status: "ERROR";
  message: string;
};

type getAccessStart = { type: typeof GET_ACCESS_START };

type getAccess = {
  type: typeof GET_ACCESS;
  payload: {
    retailers: Retailer[];
    categories: Category[];
  };
};

type getAccessError = {
  type: typeof GET_ACCESS_ERROR;
  payload: {
    error: string;
  };
};

type selectRetailers = {
  type: typeof SELECT_RETAILERS;
  payload: {
    retailers: Retailer[];
  };
};

type selectCategories = {
  type: typeof SELECT_CATEGORIES;
  payload: {
    categories: Category[];
  };
};

const accessRequest = async (accesstoken: string) => {
  const response: ServerResponse = await axios({
    url: process.env.REACT_APP_API + "/api/access",
    method: "POST",
    headers: {
      accesstoken,
    },
  });

  const { data } = response;

  return data;
};

const sortRetailers = (array: Retailer[]) =>
  _.sortBy(array, ["IndustryName", "name"]);

const sortCategories = (array: Category[]) =>
  _.sortBy(array, ["ParentName", "name"]);

export function getAccess(): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch<getAccessStart>({ type: GET_ACCESS_START });
    const { token, loggedIn } = getState().authentication;

    if (!loggedIn) return;

    try {
      const data = await accessRequest(token);

      if (data.status === "SUCCESS") {
        const retailers = sortRetailers(data.retailers);
        const categories = sortCategories(data.categories);

        dispatch<getAccess>({
          type: GET_ACCESS,
          payload: { retailers, categories },
        });
      } else {
        dispatch<getAccessError>({
          type: GET_ACCESS_ERROR,
          payload: { error: data.message },
        });
      }
    } catch (error) {
      dispatch<getAccessError>({
        type: GET_ACCESS_ERROR,
        payload: { error: error.toJSON().message },
      });
    }
  };
}

const selectFinalValue = (oldData: any[], newData: any[], allRecord: any) => {
  let result;

  const prevAll = oldData.find((r: any) => r.name === "all");
  const nextAll = newData.find((r: any) => r.name === "all");
  const pureValues = newData.filter((r: any) => r.name !== "all");

  if (prevAll && pureValues.length > 0) {
    result = pureValues;
  } else if (!prevAll && nextAll) {
    result = [allRecord];
  } else {
    result = newData;
  }

  return result;
};

export function selectRetailers(value: Retailer[]): ThunkResult<void> {
  return async (dispatch, getState) => {
    const { selectedRetailers } = getState().access;

    const allRecord = {
      name: "all",
      IndustryId: 0,
    };

    const retailers = selectFinalValue(selectedRetailers, value, allRecord);

    dispatch<selectRetailers>({
      type: SELECT_RETAILERS,
      payload: { retailers },
    });
  };
}

export function selectCategories(value: Category[]): ThunkResult<void> {
  return async (dispatch, getState) => {
    const { selectedCategories } = getState().access;

    const allRecord = {
      name: "all",
      ParentName: "",
    };

    const categories = selectFinalValue(selectedCategories, value, allRecord);

    dispatch<selectCategories>({
      type: SELECT_CATEGORIES,
      payload: { categories },
    });
  };
}

export type accessActions =
  | getAccessStart
  | getAccess
  | getAccessError
  | selectRetailers
  | selectCategories;
