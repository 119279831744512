import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";

const StyledBox = styled(Box)`
  position: absolute;
  right: -10rem;
  top: -10rem;

  @media (max-width: 1250px) {
    top: 60%;
  }
`;

const CircleBox = styled(Box)`
  height: 50rem;
  width: 50rem;
  border-radius: 50%;
  background: #ffffff;
`;

const Blog: React.FC = () => (
  <StyledBox>
    <CircleBox />
  </StyledBox>
);

export default Blog;
