import React, { FunctionComponent } from "react";
import {
  LazyLoadImage,
  LazyLoadComponent,
} from "react-lazy-load-image-component";
import Skeleton from "@material-ui/lab/Skeleton";
import MoreInfo from "./components/MoreInfo";
import InfoBox from "./components/InfoBox";
import styled, { css } from "styled-components";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography";
import { getCdnLink } from "../../../../utils/cdn";
import { PromotionWithDetails } from "../../../../types";

const StyledBox = styled(Box)`
  position: relative;
  width: 100%;
`;

const StyledText = styled(Typography)`
  color: #447eeb;
  font-weight: bold;
  font-family: Lato;
  font-style: italic;
  text-align: left;
  margin-bottom: 11px;
  width: 100%;
`;

const PromotionWrapper = styled(Box)<{ $isOpen: boolean; $isNew: boolean }>`
  margin-bottom: 30px;
  position: relative;
  box-shadow: 0px 4px 9px rgba(82, 95, 129, 0.35);
  width: 100%;

  > .more-info {
    display: none;
    visibility: hidden;
  }

  > .banner-wrapper {
    height: 250px;
    width: 100%;
    border: 1px solid rgba(82, 95, 129, 0.3);
    box-sizing: border-box;
  }

  > .banner-wrapper > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      > .more-info {
        display: flex;
        visibility: visible;
        height: auto;
        width: 100%;
        z-index: 1000;
      }
      > .banner-wrapper {
        z-index: 999;
      }
    `}

  ${({ $isNew }) =>
    $isNew &&
    css`
      > .banner-wrapper {
        border: 4px solid #447eeb !important;
      }
    `}
`;

const Promo: FunctionComponent<PromotionWithDetails> = ({
  url,
  details,
  img,
  isNew,
  campaignStartDate,
  campaignDuration,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const imgUrl =
    process.env.REACT_APP_ENV === "staging" ? img : getCdnLink(img);

  return (
    <>
      {isNew && <StyledText>NEW!</StyledText>}
      <PromotionWrapper $isOpen={open} $isNew={!!isNew}>
        <StyledBox className="banner-wrapper">
          <LazyLoadImage
            alt=""
            src={imgUrl}
            placeholder={<Skeleton variant="rect" width="100%" height={250} />}
            threshold={500}
          />
          {url && details ? (
            <LazyLoadComponent threshold={500}>
              <MoreInfo
                open={open}
                isNew={!!isNew}
                onClick={() => setOpen(!open)}
              />
            </LazyLoadComponent>
          ) : null}
        </StyledBox>
        {open && (
          <InfoBox
            url={(url || "").replace("javascript", "https")}
            campaignStartDate={campaignStartDate}
            campaignDuration={campaignDuration}
          />
        )}
      </PromotionWrapper>
    </>
  );
};

export default React.memo(Promo);
