import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Button as MaterialButton } from "@material-ui/core";

const StyledButton = styled(MaterialButton)`
    margin-left: auto;
    display: flex;
    border-radius: 50px;
    width: 90px;
    height: 46px;
    font-size: 16px;

    @media (max-width: 950px) {
        margin-right: auto;
        margin-top: 1.5rem;
    }

    @media (max-width: 600px) {
        width: 100%;
    }
`;

const Button: FunctionComponent = () => {
    return (
        <StyledButton color="primary" variant="contained" type="submit" disableRipple>
            Go
        </StyledButton>
    );
};

export default Button;
