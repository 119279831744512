import React from "react";
import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";
import * as Icons from "react-icons/md";

type Props = {
    icon?: keyof typeof Icons;
    [key: string]: any;
};

const StyledButton = styled(ButtonBase)<Props>`
    color: #447eeb;
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: bold;
    padding: 0;

    svg {
        padding-right: 6px;
        font-size: 20px;
    }

    &:active {
        color: #525f81;
    }

    &:disabled {
        color: #fafafa;
    }
`;

const TextButton: React.FC<Props> = ({ children, icon, ...props }) => {
    const IconTag = icon ? Icons[icon] : null;
    return (
        <StyledButton disableRipple {...props}>
            {IconTag && <IconTag />} {children}
        </StyledButton>
    );
};

export default TextButton;
