import * as actionTypes from "./constants";
import { promotionsActions } from "./actions";
import Promotion from "../../types/Promotion";
import View from "../../types/View";
import PromotionDetails from "../../types/PromotionDetails";

export type promotionsState = {
  loading: boolean;
  loaded: boolean;
  loadingError: string;
  promotions: Promotion[];
  promotionDetails: PromotionDetails[];
  pageTypeId: number;
  view: View;
  brandFilter: string;
  onlyNew: boolean;
  detailsLoading: boolean;
  detailsLoadingError: string;
};

const initialState: promotionsState = {
  loading: false,
  loaded: false,
  loadingError: "",
  promotions: [],
  promotionDetails: [],
  pageTypeId: 1,
  view: "stream",
  brandFilter: "",
  onlyNew: false,
  detailsLoading: false,
  detailsLoadingError: "",
};

export default (
  state: promotionsState = initialState,
  action: promotionsActions
) => {
  switch (action.type) {
    case actionTypes.SET_BRAND_FILTER:
      return {
        ...state,
        brandFilter: action.payload.brandFilter,
      };
    case actionTypes.RESET_PROMOTIONS:
      return initialState;
    case actionTypes.GET_PROMOTIONS_START:
      return {
        ...state,
        brandFilter: "",
        loading: true,
        loaded: false,
        loadingError: "",
        // promotions: [],
      };
    case actionTypes.GET_PROMOTIONS:
      const { promotions } = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        loadingError: "",
        promotions: [...state.promotions, ...promotions],
      };
    case actionTypes.GET_PROMOTIONS_ERROR:
      return {
        ...state,
        loading: false,
        loadingError: action.payload.error,
      };
    case actionTypes.GET_PROMOTION_DETAILS_START:
      return {
        ...state,
        detailsLoading: true,
        loadingError: "",
      };
    case actionTypes.GET_PROMOTION_DETAILS_ERROR:
      return {
        ...state,
        detailsLoading: false,
        detailsLoadingError: action.payload.error,
      };
    case actionTypes.GET_PROMOTION_DETAILS:
      const { promotionDetails } = action.payload;
      return {
        ...state,
        promotionDetails: [...state.promotionDetails, ...promotionDetails],
        detailsLoading: false,
      };
    case actionTypes.SELECT_FILTERS_TYPE:
      return {
        ...state,
        pageTypeId: action.payload.pageTypeId,
      };
    case actionTypes.SELECT_VIEW_TYPE:
      return {
        ...state,
        view: action.payload.view,
      };
    case actionTypes.SET_ONLY_NEW:
      return {
        ...state,
        onlyNew: action.payload.onlyNew,
      };
    default:
      return state;
  }
};
