import MomentUtils from "@material-ui/pickers/adapter/moment";
import React from "react";
import styled from "styled-components";
import { DateRangePicker, DateRange } from "@material-ui/pickers";
import { LocalizationProvider } from "@material-ui/pickers";
import { InputAdornment, TextField } from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import moment, { Moment } from "moment";
import { maxDate } from "../../../../utils/time";

type TextFieldProps = {
  [key: string]: any;
  onChangeRaw: any;
};

const StyledTextField = styled(TextField)<TextFieldProps>`
  margin-right: 1rem;
  width: 100%;

  input {
    cursor: pointer;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }

  label {
    width: 100%;
  }

  @media (max-width: 950px) {
    margin-top: 1.5rem;
  }

  @media (max-width: 600px) {
    margin-top: 1.5rem;
    width: 100%;
    max-width: 100%;
    margin-right: 0;

    input {
      width: 100%;
      max-width: 100%;
    }
  }
`;

type Props = {
  handleDateChange: any;
  selectedDate: DateRange<Moment>;
  [key: string]: any;
};

const formatDates = (dateStart: string, dateEnd: string) => {
  const start = moment(dateStart).format("DD/MM/YYYY");
  const end = moment(dateEnd).format("DD/MM/YYYY");

  if (start && end) {
    return `${start} - ${end}`;
  } else {
    return start || end;
  }
};

type CDateRangePickerProps = React.ComponentProps<typeof DateRangePicker> & {
  onChangeRaw: (e: any) => void;
};

const CDateRangePicker: React.FC<CDateRangePickerProps> = (props) => {
  return <DateRangePicker {...props}></DateRangePicker>;
};

const DatePicker: React.FC<Props> = ({
  handleDateChange,
  selectedDate,
  props,
}) => {
  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      <CDateRangePicker
        {...props}
        maxDate={maxDate()}
        error={false}
        helperText={null}
        calendars={1}
        clearable
        endText=""
        onChange={(date: any) => handleDateChange(date)}
        onChangeRaw={(e: any) => e.preventDefault()}
        startText="Timeframe"
        value={selectedDate}
        renderInput={({ inputProps, ...startProps }, endProps) => {
          const { value: startValue } = inputProps || {};
          const { value: endValue } = endProps.inputProps || {};
          if (inputProps) delete inputProps.value;

          return (
            <StyledTextField
              {...startProps}
              variant="filled"
              error={false}
              helperText={null}
              InputProps={{
                ...inputProps,
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end" style={{ marginLeft: 16 }}>
                    <CalendarToday
                      style={{
                        fontSize: 16,
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              value={formatDates(startValue, endValue)}
              size="small"
            />
          );
        }}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
