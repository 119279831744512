import { Paper } from "@material-ui/core";
import styled from "styled-components";

const StyledPaper = styled(Paper)`
  overflow: hidden !important;
  max-width: 35rem;
  // max-width: 60rem;
  width: 100%;
  min-width: calc(
    370px + 2.5rem * 2
  );
  z-index: 1000;
  background-color: #FAFAFA;
  box-shadow: 0px 4px 22px rgba(82, 95, 129, 0.2);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 1100px) {
    margin-top: 6.5rem;
    margin-bottom: 4rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
`;

export default StyledPaper;
