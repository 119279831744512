import React from "react";
import getAlphabet from "../../../../utils/getAlphabet";
import styled, { css } from "styled-components";
const Scroll = require("react-scroll");
const Link = Scroll.Link;

const StyledNavigationItemHover = css`
    font-size: 28px;
    line-height: 200%;
    position: relative;

    &::after {
        content: "";
        width: 43px;
        height: 43px;
        border: 2px solid #56ccf2;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
`;

const StyledNavigation = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    text-transform: uppercase;
    font-family: Lato, sans-serif;
    color: #525f81;
    position: fixed;
    top: calc(50% + 40px);
    transform: translateY(-50%);
    right: 2rem;
    width: 56px;
    background: rgb(255, 255, 255);
    padding: 10px 0;
    border-radius: 10px;
    z-index: 999;

    @media (max-height: 800px) {
        top: calc(50% + 80px);
    }

    @media (max-width: 600px) {
        right: 10px;
        box-shadow: 0px 4px 9px rgba(82, 95, 129, 0.1);
    }

    a:hover {
        ${StyledNavigationItemHover}
    }

    &:not(:hover) {
        a.active {
            ${StyledNavigationItemHover}
            cursor: pointer;
        }

        a.activeDisabled {
            ${StyledNavigationItemHover}
        }
    }
`;

const StyledLink = styled(Link)<{ $isEnabled: boolean }>`
    cursor: pointer;

    ${({ $isEnabled }) =>
        !$isEnabled &&
        css`
            color: rgba(82, 95, 129, 0.25);
            cursor: initial;

            &:hover::after {
                border: 2px solid rgba(82, 95, 129, 0.1) !important;
            }
        `}
`;

type Props = {
    options: string[] | any;
};

const Navigation: React.FunctionComponent<Props> = ({ options = [] }) => {
    return (
        <StyledNavigation>
            {getAlphabet().map((letter) => {
                const isEnabled = options.filter(Boolean).find((option: string) =>
                    option.toLowerCase().startsWith(letter)
                );
                return (
                    <StyledLink
                        activeClass={isEnabled ? "active" : "activeDisabled"}
                        to={letter}
                        spy={true}
                        smooth={true}
                        hashSpy={true}
                        duration={50}
                        isDynamic={true}
                        ignoreCancelEvents={false}
                        key={letter}
                        $isEnabled={isEnabled}
                        offset={-70}
                    >
                        {letter}
                    </StyledLink>
                );
            })}
        </StyledNavigation>
    );
};

export default React.memo(Navigation);
