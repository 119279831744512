import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { selectFiltersType } from "../../../../reducers/promotions/actions";
import { useDispatch } from "react-redux";
import TextButton from "../TextButton";

const StyledTextButton = styled(TextButton)`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #447eeb;
    text-decoration: none !important;

    @media (max-width: 950px) {
        margin-top: 1.5rem;
    }

    @media (max-width: 600px) {
        margin-top: 1.5rem;
    }
`;

const ExpandFilters: FunctionComponent = () => {
    const dispatch = useDispatch();
    const [filtersExpanded, setValue] = React.useState(false);

    const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        setValue(!filtersExpanded);
        dispatch(selectFiltersType(filtersExpanded ? 1 : 2));
    };

    return (
        <StyledTextButton onClick={handleChange}>
            {!filtersExpanded ? "Advanced filters" : "Close advanced"}
        </StyledTextButton>
    );
};

export default ExpandFilters;
