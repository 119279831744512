import Group from "../Group";
import React, { FunctionComponent } from "react";
import Retailer from "../../../../types/Retailer";
import { group } from "../../../../utils/group";
import RetailerLogo from "../RetailerLogo";
import Country from "../Country";
import { PromotionWithDetails } from "../../../../types";

const Scroll = require("react-scroll");
const Element = Scroll.Element;

type Props = {
  retailers: Retailer[];
  promotions: PromotionWithDetails[];
};

const GroupedPromotions: FunctionComponent<Props> = ({
  retailers,
  promotions,
}) => {
  const isLoaded = !(retailers.length === 0 || promotions.length === 0);

  const navNames = Array.from(new Set(retailers.map(({ name }) => name[0])));

  return isLoaded ? (
    <>
      {navNames.map((letter) => {
        const filteredPromotions = promotions.filter(({ retailerName }) =>
          retailerName.startsWith(letter)
        );
        const retailerGroup = group(filteredPromotions, "retailerName");
        // ["orange"', Array(31)]

        return (
          <Element name={letter} key={letter}>
            {retailerGroup.map(([retailerName, promotionsMap], index) => {
              const retailer = retailers.find(
                (retailer) => retailer.name === retailerName
              );

              const countriesGroup = group(promotionsMap, "countryCode");

              return (
                <React.Fragment key={retailerName}>
                  <RetailerLogo retailer={retailer} />
                  {countriesGroup.map(([countryName, countriesMap]) => {
                    return (
                      <React.Fragment key={countryName}>
                        <Country
                          countryName={
                            countriesGroup.length > 1 ? countryName : ""
                          }
                        />
                        <Group promotions={countriesMap} key={index} />
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </Element>
        );
      })}
    </>
  ) : null;
};

export default GroupedPromotions;
