import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import Article from "./Article";

const StyledBox = styled(Box)`
  width: 80%;
  height: 100%;
  margin: auto;
`;

type Article = {
    title: string,
    type: string,
    url: string | undefined,
    content: string,
}

const Blog: React.FC = () => {
  const [articles, setArticles] = useState<Article[]>([]);

  const fetchArticles = async (): Promise<void> => {
    const res = await fetch("/articles.json");
    const parsed = await res.json();
    setArticles(parsed);
  };

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <StyledBox>
      {articles.map(
        (
          article: Article,
          index: number
        ) => {
          const { title, type, url, content } = article;

          return (
            <Article
              key={index}
              title={title}
              type={type}
              url={url}
              content={content}
            />
          )
        }
      )}
    </StyledBox>
  );
};

export default Blog;
