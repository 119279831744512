import * as actionTypes from "./constants";
import { industriesActions } from "./actions";
import Industry from "../../types/Industry";

export type industriesState = {
    loading: boolean;
    loaded: boolean;
    loadingError: string;
    industries: Industry[];
};

const initialState: industriesState = {
    loading: false,
    loaded: false,
    loadingError: "",
    industries: [],
};

export default (
    state: industriesState = initialState,
    action: industriesActions
) => {
    switch (action.type) {
        case actionTypes.GET_INDUSTRIES_START:
            return {
                ...state,
                loading: true,
                loadingError: "",
            };
        case actionTypes.GET_INDUSTRIES:
            const { industries } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                loadingError: "",
                industries,
            };
        case actionTypes.GET_INDUSTRIES_ERROR:
            return {
                ...state,
                loading: false,
                loadingError: action.payload.error,
            };
        default:
            return state;
    }
};
