import React from "react";
import styled from "styled-components";
import TextButtonComponent from "../TextButton";
import { Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

type Props = {
    title: string;
    type: string;
    content: string;
    url?: string;
};

const StyledBox = styled(Box)`
    margin-bottom: 1rem;
`;

const TitleTypography = styled(Typography)`
    font-family: Lato !important;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    color: #313e5b;
    margin-bottom: 1rem;
`;

const TypeTypography = styled(Typography)`
    font-family: Lato !important;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    color: #4377ee;
`;

const ContentTypography = styled(Typography)`
    font-family: Karla !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
`;

const ReadMoreButton = styled(TextButtonComponent)`
    font-family: Karla !important;
    font-style: normal;
    font-weight: normal;
`;

const Article: React.FC<Props> = ({ title, type, content, url }) => (
    <StyledBox>
        <TypeTypography>{type}</TypeTypography>
        <TitleTypography>{title}</TitleTypography>
        <ContentTypography>{content}</ContentTypography>
        {url && (
            <Link to={url} style={{ textDecoration: "none" }}>
                <ReadMoreButton>Read more</ReadMoreButton>
            </Link>
        )}
    </StyledBox>
);

export default Article;
