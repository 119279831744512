import {
  GET_PROMOTIONS_START,
  GET_PROMOTIONS,
  GET_PROMOTIONS_ERROR,
  SELECT_FILTERS_TYPE,
  SELECT_VIEW_TYPE,
  RESET_PROMOTIONS,
  SET_BRAND_FILTER,
  SET_ONLY_NEW,
  GET_PROMOTION_DETAILS,
  GET_PROMOTION_DETAILS_START,
  GET_PROMOTION_DETAILS_ERROR,
} from "./constants";
import { ThunkResult } from "../../store";
import Promotion from "../../types/Promotion";
import { logout } from "../authentication/actions";
import View from "../../types/View";
import { fetchPromotions } from "../../api/promotions/fetchPromotions";
import { fetchCampaigns } from "../../api/promotions/fetchCampaigns";
import PromotionDetails from "../../types/PromotionDetails";

type setBrandFilterAction = {
  type: typeof SET_BRAND_FILTER;
  payload: {
    brandFilter: string;
  };
};

type resetPromotions = { type: typeof RESET_PROMOTIONS };

type getPromotionsStart = { type: typeof GET_PROMOTIONS_START };

type getPromotions = {
  type: typeof GET_PROMOTIONS;
  payload: {
    promotions: Promotion[];
  };
};

type getPromotionDetailsStart = { type: typeof GET_PROMOTION_DETAILS_START };

type getPromotionDetails = {
  type: typeof GET_PROMOTION_DETAILS;
  payload: {
    promotionDetails: PromotionDetails[];
  };
};

type getPromotionDetailsError = {
  type: typeof GET_PROMOTION_DETAILS_ERROR;
  payload: {
    error: string;
  };
};

type getPromotionsError = {
  type: typeof GET_PROMOTIONS_ERROR;
  payload: {
    error: string;
  };
};

type selectFiltersType = {
  type: typeof SELECT_FILTERS_TYPE;
  payload: {
    pageTypeId: number;
  };
};

type selectViewType = {
  type: typeof SELECT_VIEW_TYPE;
  payload: {
    view: View;
  };
};

type setOnlyNew = {
  type: typeof SET_ONLY_NEW;
  payload: {
    onlyNew: boolean;
  };
};

const getFinalValues = (allData: any[], selectedData: any[]) => {
  let result = [];

  if (selectedData.map((x) => x.name).some((x: any) => x === "all")) {
    result = allData;
  } else {
    result = selectedData.filter((r: any) => r.name !== "all");
  }
  result = result.map((r: any) => r.id);

  return result;
};

export function getPromotionDetails(links: string[]): ThunkResult<void> {
  return async (dispatch, getState) => {
    const { token, loggedIn } = getState().authentication;

    if (!loggedIn) {
      console.log("not logged in");
      return;
    }

    try {
      dispatch({
        type: GET_PROMOTION_DETAILS_START,
      });

      const campaigns = await fetchCampaigns(token, links);

      dispatch({
        type: GET_PROMOTION_DETAILS,
        payload: {
          promotionDetails: campaigns,
        },
      });
    } catch {
      dispatch({
        type: GET_PROMOTION_DETAILS_ERROR,
        payload: {
          error: "Something went wrong",
        },
      });
    }
  };
}

export function getPromotions(settings: {
  from: Date;
  to: Date;
  limit?: number;
  offset?: number;
}): ThunkResult<void> {
  const { from, to, limit = 50, offset = 0 } = settings;

  return async (dispatch, getState) => {
    const { token, loggedIn } = getState().authentication;

    if (!loggedIn) {
      console.log("not logged in");
      return;
    }

    dispatch<getPromotionsStart>({ type: GET_PROMOTIONS_START });

    const { categories, retailers, selectedRetailers, selectedCategories } =
      getState().access;
    const { pageTypeId } = getState().promotions;
    const finalRetailers = getFinalValues(retailers, selectedRetailers);
    const finalCategories = getFinalValues(categories, selectedCategories);

    try {
      const response = await fetchPromotions(token, {
        from,
        to,
        pageTypeId,
        categories: finalCategories,
        retailers: finalRetailers,
        limit,
        offset,
      });

      const { data } = response;

      if (data.status === "SUCCESS") {
        const urls = data.promotions.map((p: Promotion) => p.url);
        dispatch(getPromotionDetails(urls));

        dispatch<getPromotions>({
          type: GET_PROMOTIONS,
          payload: { promotions: data.promotions },
        });

        if (data.count && offset < data.count) {
          dispatch(getPromotions({ ...settings, offset: offset + limit }));
        }
      } else {
        dispatch<getPromotionsError>({
          type: GET_PROMOTIONS_ERROR,
          payload: { error: data.message },
        });
      }
    } catch (error) {
      let err: any = error;

      if (err.response) {
        const {
          response: {
            data: { name },
          },
        } = err;

        if (name === "TokenExpiredError") {
          dispatch(logout());
        }

        return;
      }

      const message = err.toJSON().message;

      dispatch<getPromotionsError>({
        type: GET_PROMOTIONS_ERROR,
        payload: { error: message },
      });
    }
  };
}

export function selectFiltersType(id: number): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECT_FILTERS_TYPE,
      payload: {
        pageTypeId: id,
      },
    });
  };
}

export function selectViewType(view: View): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECT_VIEW_TYPE,
      payload: {
        view,
      },
    });
  };
}

export function resetPromotions(): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET_PROMOTIONS,
    });
  };
}

export function setBrandFilter(value: string): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_BRAND_FILTER,
      payload: {
        brandFilter: value,
      },
    });
  };
}

export function setOnlyNewPromotions(value: boolean): ThunkResult<void> {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_ONLY_NEW,
      payload: {
        onlyNew: value,
      },
    });
  };
}

export type promotionsActions =
  | getPromotionsStart
  | getPromotions
  | getPromotionsError
  | selectFiltersType
  | selectViewType
  | resetPromotions
  | setBrandFilterAction
  | setOnlyNew
  | getPromotionDetails
  | getPromotionDetailsStart
  | getPromotionDetailsError;
