import React from "react";
import { ButtonBase } from "@material-ui/core";
import styled from "styled-components";

type Props = {
    [key: string]: any;
};

// color: ${(props) => props.theme.palette.primary.main};
// color: ${(props) => props.theme.palette.text.dark};
// color: ${(props) => props.theme.palette.grey.light};

const StyledButton = styled(ButtonBase)`
    font-size: 14px;
    line-height: 16px;
    font-style: normal;
    font-weight: bold;
    padding: 0;

    svg {
        padding-right: 6px;
        font-size: 20px;
    }

    &:active {
    }

    &:disabled {
    }
`;

const TextButton: React.FC<Props> = ({ children, ...props }) => {
    return (
        <StyledButton disableRipple {...props}>
            {children}
        </StyledButton>
    );
};

export default TextButton;
