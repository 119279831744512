import React from "react";
import styled, { css } from "styled-components";
import { Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "../../../../reducers/index";

type Props = {
  bigTextChildren: string;
  smallTextChildren: string;
  errorText?: string;
};

const StyledBox = styled(Box)`
  margin-bottom: 55px;
  color: #525F81;
  text-align: center;
`;

const StyledBigText = styled(Typography)`
  font-family: Lato !important;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
`;

const StyledSmallText = styled(Typography)<{ $errorText?: string }>`
  font-family: Karla !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-top: 10px;

  ${({ $errorText, theme }) =>
    Boolean($errorText) &&
    css`
      color: #F00F00;
    `}
`;

const Header: React.FC<Props> = ({
  bigTextChildren,
  smallTextChildren,
}) => {
  const { loadingError } = useSelector((state: AppState) => state.authentication);
  return (
    <StyledBox>
      <StyledBigText variant="body1">{bigTextChildren}</StyledBigText>
      <StyledSmallText $errorText={loadingError} variant="body1">
        {loadingError ? loadingError : smallTextChildren}
      </StyledSmallText>
    </StyledBox>
  );
};

export default Header;
