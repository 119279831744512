import authentication, { authenticationState } from "./authentication/reducer";
import promotions, { promotionsState } from "./promotions/reducer";
import access, { accessState } from "./access/reducer";
import industries, { industriesState } from "./industries/reducer";
import { authenticationActions } from "./authentication/actions";
import { combineReducers } from "redux";
import { promotionsActions } from "./promotions/actions";
import { accessActions } from "./access/actions";
import { industriesActions } from "./industries/actions";

export type AppState = {
  authentication: authenticationState;
  promotions: promotionsState;
  access: accessState;
  industries: industriesState;
};

export type Actions =
  | authenticationActions
  | promotionsActions
  | accessActions
  | industriesActions;

export const reducers = combineReducers({
  authentication,
  promotions,
  access,
  industries,
});
