import Form from "./components/Form";
import GroupedPromotions from "./components/GroupedPromotions/GroupedPromotions";
import LoadingStatus from "../LoadingStatus";
import React, { FunctionComponent, useEffect, useMemo } from "react";
import { AppState } from "../../reducers";
import { Container } from "@material-ui/core";
import { getAccess } from "../../reducers/access/actions";
import { getIndustries } from "../../reducers/industries/actions";
import {
  getPromotions,
  resetPromotions,
} from "../../reducers/promotions/actions";
import { start, end } from "../../utils/time";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import Navigation from "./components/Navigation";
import _ from "lodash";
import Promotion from "../../types/Promotion";
import Retailer from "../../types/Retailer";
import { PromotionWithDetails } from "../../types";

const StyledMain = styled.main`
  margin-top: 25px;
`;

const getFilteredPromotions = (
  promotions: PromotionWithDetails[],
  filter: string,
  onlyNew: boolean
): Promotion[] => {
  let filteredPromotions = promotions;

  if (filter && filter.length >= 2) {
    filteredPromotions = filteredPromotions.filter(
      (p) => p.url && p.url.includes(filter)
    );
  }

  if (onlyNew) {
    filteredPromotions = filteredPromotions.filter((p) => p.isNew);
  }

  return filteredPromotions;
};

const getActiveRetailers = (
  promotions: Promotion[],
  retailers: Retailer[]
): string[] => {
  const activeRetailersIds = _.uniq(
    promotions.map((promotion) => promotion.RetailerId)
  );

  const activeRetailers = activeRetailersIds.map(
    (id) => retailers.find((r) => r.id === id)?.name || ""
  );

  return activeRetailers;
};

const Promotions: FunctionComponent = () => {
  const dispatch = useDispatch();

  const loggedIn = useSelector(
    (state: AppState) => state.authentication.loggedIn
  );
  const promotions = useSelector(
    (state: AppState) => state.promotions.promotions
  );
  const promotionDetails = useSelector(
    (state: AppState) => state.promotions.promotionDetails
  );
  const loaded = useSelector((state: AppState) => state.promotions.loaded);
  const loading = useSelector((state: AppState) => state.promotions.loading);
  const brandFilter = useSelector(
    (state: AppState) => state.promotions.brandFilter
  );
  const onlyNew = useSelector((state: AppState) => state.promotions.onlyNew);
  const { retailers } = useSelector((state: AppState) => state.access);

  const promotionsWithDetails = useMemo(() => {
    if (!promotionDetails) {
      return promotions;
    } else {
      return promotions.map((p) => {
        const details = promotionDetails.find((pd) => pd.url === p.url);

        if (details) {
          return {
            ...p,
            ...details,
            details: true,
          };
        }

        return {
          ...p,
          details: false,
          isNew: false,
          campaignDuration: undefined,
          campaignStartDate: undefined,
        };
      });
    }
  }, [promotions, promotionDetails]);

  const filteredPromotions = useMemo(
    () => getFilteredPromotions(promotionsWithDetails, brandFilter, onlyNew),
    [promotionsWithDetails, brandFilter, onlyNew]
  );

  const activeRetailers = useMemo(() => {
    return getActiveRetailers(filteredPromotions, retailers);
  }, [filteredPromotions, retailers]);

  useEffect(() => {
    dispatch(getIndustries());
    dispatch(getAccess());
  }, [dispatch, loggedIn]);

  const readyToFetch = useMemo(() => {
    return retailers && retailers.length > 0;
  }, [retailers]);

  useEffect(() => {
    if (readyToFetch) {
      dispatch(resetPromotions());

      const date = new Date();

      const settings = {
        from: start(date),
        to: end(date),
      };

      dispatch(getPromotions(settings));
    }
  }, [loggedIn, dispatch, readyToFetch]);

  return (
    <StyledMain>
      <Container>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12}>
            <Form />
          </Grid>
          <Grid item xs={10}>
            <LoadingStatus
              array={filteredPromotions}
              failureMessage={"No promotions available"}
              loaded={loaded}
              loading={loading}
            />
            <GroupedPromotions
              promotions={filteredPromotions}
              retailers={retailers}
            />
          </Grid>
          <Navigation options={activeRetailers} />
        </Grid>
      </Container>
    </StyledMain>
  );
};

export default Promotions;
