import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { PromotionWithDetails } from "../../../../types";
import Promo from "../Promo";

const StyledWrapper = styled.div`
  > div {
    overflow: visible;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

type Props = {
  promotions: PromotionWithDetails[];
};

const Group: FunctionComponent<Props> = ({ promotions }) => (
  <StyledWrapper>
    {promotions.map((data, index) => {
      return <Promo {...data} key={index} />;
    })}
  </StyledWrapper>
);

export default Group;
