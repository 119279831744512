import React, { FunctionComponent } from "react";
import Retailer from "../../../../types/Retailer";
import Title from "../../../Atoms/Title";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Skeleton from "@material-ui/lab/Skeleton";

const LogoLink = styled.a`
    display: inline-block;
    cursor: pointer;
    margin: auto;
`;

const LogoWrapper = styled.div`
    width: 100%;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${({
            theme: {
                colors: { neutrals },
            },
        }) => neutrals[300]};
        display: block;
        z-index: -1;
    }

    img {
        height: 50px;
        width: auto;
        display: block;
        object-fit: contain;
        max-width: 250px;
        background: white;
        padding: 20px 20px 20px 0;

        @media (max-width: 600px) {
            margin-top: 20px;
            margin-bottom: 1rem;
            height: 50px;
        }
    }
`;

type Props = {
    retailer?: Retailer;
};

const RetailerLogo: FunctionComponent<Props> = ({ retailer }) => {
    if (!retailer) return null;

    const { logo, name, url } = retailer;

    return (
        <LogoWrapper>
            <LogoLink href={url} target="_blank">
                {logo ? (
                    <LazyLoadImage
                        alt={name}
                        src={logo}
                        placeholder={
                            <Skeleton variant="rect" width={100} height={50} style={{ margin: '20px 0'}}/>
                        }
                        threshold={500}
                    />
                ) : (
                    <Title>{name}</Title>
                )}
            </LogoLink>
        </LogoWrapper>
    );
};

export default RetailerLogo;
