import React, { FunctionComponent } from "react";
import ButtonBase from "@material-ui/core/ButtonBase";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import styled, { css } from "styled-components";

type Props = {
    onClick: () => void;
    open: boolean;
    isNew: boolean;
};

const StyledButton = styled(ButtonBase)<{ $open: boolean; $isNew: boolean }>`
    position: absolute;
    height: 45px;
    width: 45px;
    border-radius: 0;
    color: #fff;
    background-color: #447eeb;
    right: -1px;
    bottom: -1px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        -1px 0px 4px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(82, 95, 129, 0.3);
    box-sizing: border-box;

    ${({ $open }) =>
        $open &&
        css`
            opacity: 0.95;
            color: #447eeb;
            background-color: #fff;
            mix-blend-mode: normal;
            box-shadow: none;
            opacity: 0.9;
            border-bottom: none;
        `}

    ${({ $isNew }) =>
        $isNew &&
        css`
            right: -4px;
            bottom: -4px;
        `}
`;

const MoreInfo: FunctionComponent<Props> = ({ onClick, open, isNew }) => {
    return (
        <StyledButton $open={open} $isNew={isNew} onClick={onClick}>
            {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </StyledButton>
    );
};

export default React.memo(MoreInfo);
