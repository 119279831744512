import Box from "@material-ui/core/Box";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../../reducers";
import { Skeleton } from "@material-ui/lab";

type Props = {
  campaignStartDate: string | undefined;
  campaignDuration: string | undefined;
  url: string;
};

const StyledBox = styled(Box)`
  background-color:#fff
  width: 960px;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  display: flex;

  opacity: 0.9;
  border: 1px solid rgba(82, 95, 129, 0.3);
  box-sizing: border-box;
  border-top: none;

  @media all and (max-width: 1150px) {
    width: 680px;
    }
`;

const RowBox = styled(Box)`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  width: 95%;
`;

const DateBox = styled(Box)`
  margin-right: 3rem;
`;

const TitleTypography = styled(Typography)`
  text-transform: uppercase;
  font-weight: 400;
  text-align: left;
  font-size: 12px;
`;

const ValueTypography = styled(Typography)`
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  font-size: 14px;
`;

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  font-weight: 400;
  align-items: center;
  color: #447eeb;
  text-decoration: none !important;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  margin-left: 10px;
`;

const InfoBox: FunctionComponent<Props> = ({
  url,
  campaignStartDate,
  campaignDuration,
}) => {
  const detailsLoading = useSelector(
    (state: AppState) => state.promotions.detailsLoading
  );

  return (
    <StyledBox className="more-info">
      <RowBox display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" flexDirection="row">
          <DateBox
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <TitleTypography>Campaign first seen</TitleTypography>
            {detailsLoading ? (
              <Skeleton width={144} height={40} />
            ) : (
              <ValueTypography>{campaignStartDate}</ValueTypography>
            )}
          </DateBox>
          <DateBox
            display="flex"
            alignItems="flex-start"
            flexDirection="column"
          >
            <TitleTypography>Campaign seen</TitleTypography>
            {detailsLoading ? (
              <Skeleton width={100} height={40} />
            ) : (
              <ValueTypography>{campaignDuration}</ValueTypography>
            )}
          </DateBox>
        </Box>
        {url && (
          <StyledLink href={url} target="_blank" rel="noopener noreferrer">
            Go to website <StyledArrowForwardIcon />
          </StyledLink>
        )}
      </RowBox>
    </StyledBox>
  );
};

export default React.memo(InfoBox);
