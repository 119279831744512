const getCdnLink = (url: string): string => {
    const cdnLink = "https://cdn.banners.modvise.com/";
    const bucketLink =
        "https://promotions-monitor-dev.s3.eu-west-1.amazonaws.com/";
    const result = url.includes(bucketLink)
        ? url.replace(bucketLink, cdnLink)
        : url;
    return result;
};

export { getCdnLink };
