import "./amplify";
import * as serviceWorker from "./serviceWorker";
import Main from "./main";
import NoSsr from "@material-ui/core/NoSsr";
import React from "react";
import ReactDOM from "react-dom";
import Theme from "./appTheme";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { StylesProvider } from "@material-ui/core/styles";
import { store, persistor } from "./store";

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StylesProvider injectFirst>
          <NoSsr>
            <Theme>
              <Main />
            </Theme>
          </NoSsr>
        </StylesProvider>
      </PersistGate>
    </Provider>
  </>,
  document.getElementById("root")
);

serviceWorker.unregister();
