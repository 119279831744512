import axios from "axios";
import PromotionDetails from "../../types/PromotionDetails";
import { cluster, parallel } from "radash";

type ServerResponse = SuccessServerResponse | FailureServerResponse;

type SuccessServerResponse = {
  data: SuccessResponse;
};

type FailureServerResponse = {
  data: FailureResponse;
};

type SuccessResponse = {
  status: "SUCCESS";
  campaigns: PromotionDetails[];
};

type FailureResponse = {
  status: "ERROR";
  message: string;
};

const fetchCampaign = async (
  token: string,
  links: string[]
): Promise<ServerResponse> => {
  const response: ServerResponse = await axios({
    url: process.env.REACT_APP_API + "/api/campaigns",
    method: "POST",
    headers: {
      accesstoken: token,
    },
    data: {
      links,
    },
  });

  return response;
};

const fetchCampaigns = async (token: string, links: string[]) => {
  const batches = cluster(links, 500);

  const responses = await parallel(1, batches, async (_links) => {
    return await fetchCampaign(token, _links);
  });

  const successRespones = responses.filter(
    (r) => r.data.status === "SUCCESS"
  ) as SuccessServerResponse[];

  const campaigns = successRespones.flatMap((sr) => sr.data.campaigns);

  return campaigns;
};

export { fetchCampaigns };
