import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, CircularProgress, TextField } from "@material-ui/core";
import ButtonComponent from "../Button";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../../../reducers/index";
import {
    login,
    resetLoginLoader,
} from "../../../../reducers/authentication/actions";
import qs from "query-string";
import { Redirect } from "react-router-dom";
import Header from "../Header";

const StyledBox = styled(Box)`
    min-width: 270px;
    width: 100%;
    color: #447eeb;
`;

const StyledForm = styled.form`
    width: auto;
    max-width: 370px;
    margin: auto;

    > div:not(:first-child) {
        margin-top: 20px;
    }
`;

const StyledButton = styled(ButtonComponent)`
    margin-top: 2rem;
`;

const StyledInputWrapper = styled.div``;

const LoginForm: React.FC = () => {
    const dispatch = useDispatch();
    const { loggedIn, loadingError, loading } = useSelector(
        (state: AppState) => state.authentication
    );
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [shouldComplete, setShouldComplete] = useState<boolean>(false);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        dispatch(login(email, password, newPassword));
    };

    useEffect(() => {
            setShouldComplete(false);
    }, []);

    useEffect(() => {
        if (loadingError === "NEW_PASSWORD_REQUIRED") {
            setShouldComplete(true);
        }
    }, [loadingError]);

    useEffect(() => {
        const { email } = qs.parse(window.location.search);
        if (email && typeof email === "string") setEmail(email);
        dispatch(resetLoginLoader());
    }, [dispatch]);

    if (loggedIn) {
        return <Redirect to="/" />;
    }

    return (
        <StyledBox>
            <Header
                bigTextChildren="Welcome"
                smallTextChildren="Log in to continue"
            />
            <StyledForm onSubmit={handleSubmit} autoComplete="off">
                <StyledInputWrapper>
                    <TextField
                        autoComplete="username"
                        id="email"
                        label="Email"
                        type="email"
                        fullWidth
                        autoFocus
                        required
                        value={email}
                        variant="filled"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.target.value)
                        }
                    />
                </StyledInputWrapper>
                <StyledInputWrapper>
                    <TextField
                        autoComplete="current-password"
                        id="password"
                        label="Password"
                        type="password"
                        fullWidth
                        required
                        variant="filled"
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPassword(e.target.value)
                        }
                    />
                </StyledInputWrapper>
                {shouldComplete && (
                    <StyledInputWrapper>
                        <TextField
                            autoComplete="new-password"
                            id="newPassword"
                            label="New Password"
                            type="password"
                            fullWidth
                            required
                            variant="filled"
                            value={newPassword}
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => setNewPassword(e.target.value)}
                        />
                    </StyledInputWrapper>
                )}
                <Box display="flex" justifyContent="center">
                    <StyledButton size="large" type="submit" disabled={loading}>
                        {loading ? (
                            <CircularProgress size={25} color="inherit" />
                        ) : (
                            "Log in"
                        )}
                    </StyledButton>
                </Box>
            </StyledForm>
        </StyledBox>
    );
};

export default LoginForm;
