import Filter from "../..";
import React from "react";
import { AppState } from "../../../../../../reducers";
import { selectCategories } from "../../../../../../reducers/access/actions";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Grid } from "@material-ui/core";

const StyledCategories = styled(Grid)`
    @media (max-width: 950px) {
        margin-top: 1.5rem;
    }

    @media (max-width: 600px) {
        width: 100%;
        max-width: 100%;

        > div,
        input {
            width: 100%;
            max-width: 100%;
        }
    }
`;

const allRecord = {
    name: "all",
    ParentName: "",
    id: 0
};

const Categories = () => {
    const dispatch = useDispatch();
    const { categories: rawCategories, selectedCategories } = useSelector(
        (state: AppState) => state.access
    );
    const { pageTypeId } = useSelector((state: AppState) => state.promotions);
    const categories = [allRecord, ...rawCategories];

    const handleChange = (e: any, value: any) => {
        dispatch(selectCategories(value));
    };

    const onLoad = () => {
        dispatch(selectCategories([allRecord]));
    };

    return rawCategories.length > 0 && pageTypeId === 2 ? (
        <StyledCategories
            item
            xs={12}
            md={3}
            sm={4}
        >
            <Filter
                onLoad={onLoad}
                selected={selectedCategories}
                options={categories}
                handleChange={handleChange}
                label="Categories"
                groupBy="ParentName"
            />
        </StyledCategories>
    ) : null;
};

export default Categories;
