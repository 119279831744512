import App from "./containers/App";
import Login from "./containers/Login";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { resetPromotions } from "./reducers/promotions/actions";

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    ReactGA.initialize("UA-162171452-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
    dispatch(resetPromotions());
  }, [dispatch]);

  return (
    <>
      <Router>
        <Route exact path="/" component={App}></Route>
        <Route exact path="/login*" component={Login}></Route>
      </Router>
    </>
  );
};

export default Main;
