import { createMuiTheme, ThemeOptions } from "@material-ui/core/styles";

const black = "#000000";
// const white = "#FFFFFF";

const primaryBlue = "#447EEB";
const accentBlue = "#437EEB";
const darkBlue = "#286DCB";

const primaryAzure = "#4BD9EC";
const accentAzure = "#4BD9EC";
const darkAzure = "#4BD9EC";

const gray2 = "#FAFAFA";
// const gray3 = "#828282";
const gray4 = "#BDBDBD";
const gray5 = "#E0E0E0";
const gray6 = "#F2F2F2";

const mediumGrey = "rgba(82, 95, 129, 0.1)";

const textColor = "#525F81";

// const errorColor = "#F00F00";

// const autocompleteOption = {
//     paddingLeft: "7px",
//     '&[aria-selected="true"]': {
//         backgroundColor: "rgba(82, 95, 129, 0.1)", // textColor with opacity 0.1
//     },
//     '&[data-focus="true"]': {
//         backgroundColor: "rgba(82, 95, 129, 0.05)",
//     },
//     "&:active": {
//         backgroundColor: "rgba(82, 95, 129, 0.11)",
//     },
// };

export const theme: ThemeOptions = {
    typography: {
        button: {
            fontFamily: "Karla",
        },
    },
    palette: {
        primary: {
            light: accentBlue,
            main: primaryBlue,
            dark: darkBlue,
            contrastText: "#fff",
        },
        secondary: {
            light: accentAzure,
            main: primaryAzure,
            dark: darkAzure,
            contrastText: "#fff",
        },
        grey: {
            100: gray2,
            200: gray4,
            400: mediumGrey,
            600: textColor,
        },
        text: {
            // extraLight: gray3,
            secondary: gray6,
            primary: black,
            disabled: textColor,
        },
    },
    overrides: {
        MuiInputBase: {
            input: {
                "&:-webkit-autofill": {
                    transitionDelay: "9999s",
                    transitionProperty: "background-color, color",
                },
            },
        },
        MuiRadio: {
            root: {
                color: gray4,
                paddingTop: "6px",
                paddingBottom: "6px",
            },
            // icon: {
            //     width: 10,
            //     height: 10,
            // },
        },
        MuiFormControlLabel: {
            label: {
                color: black,
                fontSize: "14px",
                lineHeight: "16px",
                fontFamily: "Karla"
            },
        },
        MuiIconButton: {
            label: {
                fontSize: "14px",
                lineHeight: "16px",
            },
        },
        MuiButton: {
            root: {
                borderRadius: "30px",
            },
            contained: {
                boxShadow: "0px 4px 9px rgba(82, 95, 129, 0.35)",
                "&.Mui-disabled": {
                    background: gray4,
                    boxShadow: "none",
                },
            },
            containedPrimary: {
                "&:hover": {
                    backgroundColor: primaryBlue,
                    boxShadow: "0px 6px 11px rgba(82, 95, 129, 0.37)",
                },

                "&:active": {
                    boxShadow: "inset 0px 4px 9px rgba(82, 95, 129, 0.55)",
                },
            }
        },
        MuiCheckbox: {
            root: {
                color: gray4,
            },
        },
        MuiSwitch: {
            switchBase: {
                color: "#F1F1F1",
            },
            thumb: {
                boxShadow:
                    "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
            },
            track: {
                backgroundColor: "rgba(34, 31, 31, 0.26)",
                opacity: 1,
            },
        },
        MuiLinearProgress: {
            colorPrimary: {
                backgroundColor: gray5,
            },
        },
        MuiInputLabel: {
            root: {
                color: textColor,
                fontSize: "14px",
                lineHeight: "16px",
            },
        },
        MuiInputAdornment: {
            filled: {
                marginTop: "0 !important",
            },
        },
        // MuiPickersDateRangePickerInput: {
        //     root: {
        //         "& p": {
        //             display: "none",
        //         },
        //     },
        // },
        // MuiAutocomplete: {
        //     popper: {
        //         top: "-2px !important",
        //     },
        //     paper: {
        //         boxShadow: "0px 4px 9px rgba(82, 95, 129, 0.35)",
        //         borderRadius: "0px 0px 5px 5px",
        //         opacity: "0.95",
        //     },
        //     groupUl: {
        //         "& .MuiAutocomplete-option": autocompleteOption,
        //     },
        //     option: autocompleteOption,
        //     groupLabel: {
        //         color: textColor,
        //         fontSize: "16px",
        //         fontWeight: "bold",
        //     },
        // },
        MuiFilledInput: {
            root: {
                fontSize: "14px",
                fontWeight: "bold",
                lineHeight: "16px",
                color: textColor,
                borderRadius: "5px 5px 0px 0px",
                backgroundColor: "rgba(82, 95, 129, 0.1)",

                "&.Mui-focused": {
                    backgroundColor: "rgba(82, 95, 129, 0.1)",
                    fontWeight: "normal",
                },

                "&.Mui-disabled": {
                    backgroundColor: "rgba(82, 95, 129, 0.1)",
                    fontWeight: "normal",
                    color: gray4,
                },

                "&:hover": {
                    backgroundColor: "rgba(82, 95, 129, 0.1)",
                },
            },
            underline: {
                borderBottomWidth: "2px",

                "&.Mui-disabled:before": {
                    borderBottomStyle: "solid",
                    borderBottom: "2px solid #E0E0E0",
                },
            },
        },
    },
};

export default createMuiTheme(theme);
