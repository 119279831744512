import React from "react";
import { Box } from "@material-ui/core";
import styled from "styled-components";

type Props = {
  width: number;
  height: number;
  background: string;
  margin?: string;
};

const StyledLine = styled(Box)<Props>`
  border-radius: 0px 10px 10px 0px;
  background: ${({ background }) => background};
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ margin }) => (margin ? "margin:" + margin + ";" : "")}
`;

const Line: React.FC<Props> = (props) => {
  return (
    <div>
      <StyledLine {...props} />
    </div>
  );
};

export default Line;
