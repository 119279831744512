import axios from "axios";
import Promotion from "../../types/Promotion";

type ServerResponse = {
  data: SuccessResponse | FailureResponse;
};

type SuccessResponse = {
  status: "SUCCESS";
  promotions: Promotion[];
  count: number;
};

type FailureResponse = {
  status: "ERROR";
  message: string;
};

type FetchPromotionsOptions = {
  from: Date;
  to: Date;
  retailers: number[];
  categories: number[];
  pageTypeId: number;
  offset?: number;
  limit?: number;
};

const fetchPromotions = async (
  token: string,
  options: FetchPromotionsOptions
): Promise<ServerResponse> => {
  const response: ServerResponse = await axios({
    url: process.env.REACT_APP_API + "/api/promotions2",
    method: "POST",
    headers: {
      accesstoken: token,
    },
    data: options,
  });

  return response;
};

export { fetchPromotions };
