import React from "react";
import { Box } from "@material-ui/core";

import BackgroundLines from "./Lines/index";
import BackgroundCircle from "./Circle/index";
import BackgroundFancy from "./Fancy/index";

const BackgroundElements = () => (
  <Box>
    <BackgroundLines hideForMobile={true} />
    <BackgroundCircle />
    <BackgroundFancy right="10rem" top="4rem" />
    <BackgroundFancy left="10rem" bottom="4rem" />
  </Box>
);

export default BackgroundElements;
