import React, { FunctionComponent } from "react";
import styled from "styled-components";
import ReactCountryFlag from "react-country-flag";

type Props = {
    countryName?: string;
};

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 2rem auto;
`;

const Country: FunctionComponent<Props> = ({ countryName }) => {
    if (!countryName) return null;

    return (
        <>
            <StyledWrapper>
                <ReactCountryFlag
                    countryCode={countryName}
                    style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                    }}
                    aria-label="United States"
                    svg
                />
            </StyledWrapper>
        </>
    );
};

export default Country;
