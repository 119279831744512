import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Popper, Typography } from "@material-ui/core";
import styled from "styled-components";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon color="primary" fontSize="small" />;

const StyledTypography = styled(Typography)`
    font-family: Karla;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    margin-left: 5px;
    text-transform: capitalize;
`;

const StyledDiv = styled.div`
    text-transform: capitalize;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
`;

const StyledIndustry = styled(Typography)`
    text-transform: capitalize;
    font-family: Karla;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-left: 20px;
`;

const StyledTextField = styled(TextField)`
`;

const CustomPopper = function (props: any) {
    return <Popper {...props} style={{ zIndex: 999 }} />;
};

type Props = {
    handleChange: any;
    onLoad: (() => void) | null;
    selected: any[];
    options: any[];
    label: string;
    groupBy?: string;
};

const Filter: React.FC<Props> = ({
    handleChange,
    onLoad,
    selected,
    options,
    label,
    groupBy,
}) => {
    const [isFocus, setFocus] = useState<boolean>(false);
    const [isLoaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (isLoaded === false && onLoad) {
            onLoad();
            setLoaded(true);
        }
    }, [isLoaded, onLoad]);

    const renderGroup = (params: any) => [
        <StyledDiv key={params.group}>
            {false && (
                <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={false}
                    style={{ marginRight: 8 }}
                />
            )}
            <StyledIndustry>{params.group}</StyledIndustry>
        </StyledDiv>,
        params.children,
    ];

    return (
        <>
            <Autocomplete
                disablePortal
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                getOptionSelected={(a, b) => a.name === b.name}
                groupBy={(option) => (groupBy ? option[groupBy] : "")}
                limitTags={1}
                multiple
                onBlur={() => setFocus(false)}
                onChange={handleChange}
                onFocus={() => setFocus(true)}
                options={options}
                style={{ marginRight: "1rem", minWidth: "10rem" }}
                value={selected}
                PopperComponent={CustomPopper}
                size="small"
                renderOption={(option, { selected }) => (
                    <StyledDiv key={option}>
                        <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            checked={selected}
                            style={{ marginRight: 8 }}
                            color="primary"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                        />
                        {option.name}
                    </StyledDiv>
                )}
                renderTags={(value) =>
                    !isFocus &&
                    value.map((option, index) => (
                        <StyledTypography
                            key={index}
                        >
                             {option.name}
                        </StyledTypography>
                    ))
                }
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        variant="filled"
                        label={label}
                        placeholder={!selected.length ? "Select" : ""}
                    />
                )}
                renderGroup={renderGroup}
                getLimitTagsText={(more) => `+ ${more}`}
            />
        </>
    );
};

export default Filter;
