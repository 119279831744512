import _ from "lodash";

const group = (source: any[], key: string) => {
    const result = _.chain(source)
        .groupBy(key)
        .entries()
        .sortBy(([groupName]) => groupName)
        .value();

    return result;
}

export { group };