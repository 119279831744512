import styled from "styled-components";

const Title = styled.h2`
    margin-top: 2rem;
    margin-bottom: 0;
    font-weight: 300;
    color: ${({theme: { colors: { neutrals }}}) => neutrals[700]};
    text-align: center;
`;

export default Title;