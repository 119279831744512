import React, { ReactChild, FunctionComponent } from "react";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import material from "./themes/material";

// Palette 2
const theme = {
    colors: {
        neutrals: {
            100: "#F0F4F8",
            200: "#D9E2EC",
            300: "#F2F2F2",
            400: "#9FB3C8",
            500: "#829AB1",
            600: "#627D98",
            700: "#486581",
            800: "#334E68",
            900: "#243B53",
            1000: "#243B53"
        }
    }
};

type Props = {
    children: ReactChild;
};

const Theme: FunctionComponent<Props> = ({ children }) => (
    <ThemeProvider theme={theme}><MuiThemeProvider theme={material}>{children}</MuiThemeProvider></ThemeProvider>
);

export default Theme;
