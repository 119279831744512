import Button from "../Button";
import Categories from "../Filter/components/Categories";
import DatePicker from "../DatePicker";
import React, { FunctionComponent } from "react";
import Retailers from "../Filter/components/Retailers";
import { DateRange } from "@material-ui/pickers";
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core";
import {
  getPromotions,
  resetPromotions,
  setOnlyNewPromotions,
} from "../../../../reducers/promotions/actions";
import { now, start, end } from "../../../../utils/time";
import { useDispatch, useSelector } from "react-redux";
import ExpandFilters from "../ExpandFilters";
import { AppState } from "../../../../reducers";
import styled from "styled-components";
import { Moment } from "moment";

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 10px;

  .MuiFormControlLabel-label {
    color: #525f81;
  }
`;

const Form: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state: AppState) => state.access);
  const { onlyNew } = useSelector((state: AppState) => state.promotions);
  const [selectedDate, handleDateChange] = React.useState<DateRange<Moment>>([
    now(),
    now(),
  ]);

  const handleApply = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const [from, to] = selectedDate;

    const settings = {
      from: start(from),
      to: end(to),
    };

    dispatch(resetPromotions());
    dispatch(getPromotions(settings));
  };

  const handleCheckboxChange = () => {
    dispatch(setOnlyNewPromotions(!onlyNew));
  };

  return (
    <form onSubmit={handleApply}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={12} md={3}>
          <DatePicker
            handleDateChange={handleDateChange}
            selectedDate={selectedDate}
          />
        </Grid>
        <Retailers />
        <Categories />
        {categories.length > 0 ? (
          <>
            <Grid item xs={12} sm={2} md>
              <ExpandFilters />
            </Grid>
            <Grid item xs={12} sm={2} md={1}>
              <Button />
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={2} md>
            <Button />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" alignItems="center">
        <Grid item xs={12} sm={2} md={3}>
          <StyledFormControlLabel
            control={
              <Checkbox
                checked={onlyNew}
                onChange={handleCheckboxChange}
                name="checkedB"
                color="primary"
              />
            }
            label="Only new"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default Form;
