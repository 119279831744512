import React from "react";
import Line from "./Line";
import { Box } from "@material-ui/core";
import styled from "styled-components";

type Props = {
  hideForMobile?: boolean;
};

const StyledBox = styled(Box)<{ $hideForMobile: boolean }>`
  position: absolute;
  top: 32px;
  left: -31px;

  @media (max-width: 900px) {
    ${({ $hideForMobile }) => ($hideForMobile ? "display:none;" : "")}
  }
`;

const BackgroundLines: React.FC<Props> = ({ hideForMobile = false }) => (
  <StyledBox $hideForMobile={hideForMobile}>
    <Line
      background="linear-gradient(270deg, rgba(82, 95, 129, 0.1) 50%, rgba(255, 255, 255, 0) 96.79%);"
      height={13}
      width={146}
      margin="0 0 13px 0"
    />
    <Line
      background="linear-gradient(270deg, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 97.42%);"
      height={12}
      width={274}
      margin="0 0 18px 0"
    />
    <Line
      background="linear-gradient(270deg, rgba(82, 95, 129, 0.1) 50%, rgba(255, 255, 255, 0) 96.79%);"
      height={13}
      width={186}
      margin="0 0 0 0"
    />
  </StyledBox>
);

export default BackgroundLines;
