import "./style.css";
import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Promotions from "../../components/Promotions";
import { useSelector, useDispatch } from "react-redux";
import { AppState } from "../../reducers";
import { Redirect } from "react-router-dom";
import { selectFiltersType } from "../../reducers/promotions/actions";

const App = () => {
  const dispatch = useDispatch();
  const { loggedIn, companyId } = useSelector(
    (state: AppState) => state.authentication
  );

  useEffect(() => {
    dispatch(selectFiltersType(1));
  }, [dispatch]);

  if (!loggedIn) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <Header companyId={companyId} />
      <Promotions />
      <Footer />
    </>
  );
};

export default App;
