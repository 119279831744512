import React, { FunctionComponent } from 'react';
import Container from '@material-ui/core/Container';
import styled from 'styled-components';

const StyledCopyright = styled.div`
    text-align: center;
    font-size: .9rem;
    font-weight: 300;
    color: ${({theme: { colors: { neutrals }}}) => neutrals[700]};
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Footer: FunctionComponent = () => {
  return (
    <footer>
      <Container>
        <StyledCopyright>
        Modvise &copy; {new Date().getFullYear()} 
        </StyledCopyright>
      </Container>
    </footer>
  );
};

export default Footer;
