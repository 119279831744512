import * as actionTypes from "./constants";
import { LOGOUT } from "../constants";
import { Actions } from "../index";
import Retailer from "../../types/Retailer";
import Category from "../../types/Category";

export type accessState = {
    categories: Category[];
    loaded: boolean;
    loading: boolean;
    loadingError: string;
    retailers: Retailer[];
    selectedRetailers: Retailer[];
    selectedCategories: Category[];
};

const initialState: accessState = {
    categories: [],
    loaded: false,
    loading: false,
    loadingError: "",
    retailers: [],
    selectedRetailers: [],
    selectedCategories: [],
};

export default (state: accessState = initialState, action: Actions) => {
    switch (action.type) {
        case actionTypes.GET_ACCESS_START:
            return {
                ...state,
                loading: true,
                loadingError: "",
            };
        case actionTypes.GET_ACCESS:
            const { retailers, categories } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                loadingError: "",
                retailers,
                categories,
            };
        case actionTypes.GET_ACCESS_ERROR:
            return {
                ...state,
                loading: false,
                loadingError: action.payload.error,
            };
        case actionTypes.SELECT_RETAILERS:
            return {
                ...state,
                selectedRetailers: action.payload.retailers,
            };
        case actionTypes.SELECT_CATEGORIES:
            return {
                ...state,
                selectedCategories: action.payload.categories,
            };
        case LOGOUT:
            return {
                ...state,
                categories: [],
                retailers: [],
                loaded: false,
            };
        default:
            return state;
    }
};
