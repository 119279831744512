import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const LogoBox = styled(Link)`
    position: absolute;
    top: 1rem;
    margin: 0 auto;
    z-index: 1000;
`;

const LogoImg = styled.img`
    width: ${(props) => (props.width ? props.width : "12rem")};
    height: auto;
    cursor: pointer;
`;

type Props = {
    withWrapper?: boolean;
};

const Logo: React.FC<Props> = ({ withWrapper = false }) =>
    withWrapper ? (
        <LogoBox to="/" style={{ textDecoration: "none" }}>
            <LogoImg src="/logo.png" alt="" />
        </LogoBox>
    ) : (
        <LogoImg src="/logo.png" alt="" />
    );

export default Logo;
