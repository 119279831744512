import * as actionTypes from "./constants";
import { authenticationActions } from "./actions";

export type authenticationState = {
    loading: boolean;
    loaded: boolean;
    loggedIn: boolean;
    loadingError: string;
    token: string;
    userId?: number;
    companyId?: number;
};

const initialState: authenticationState = {
    loading: false,
    loggedIn: false,
    loaded: false,
    loadingError: "",
    token: "",
    userId: undefined,
    companyId: undefined,
};

export default (
    state: authenticationState = initialState,
    action: authenticationActions
) => {
    switch (action.type) {
        case actionTypes.LOGIN_START:
            return {
                ...state,
                loading: true,
                loadingError: "",
            };
        case actionTypes.LOGIN:
            const { token } = action.payload;
            return {
                ...state,
                loading: false,
                loaded: true,
                loadingError: "",
                loggedIn: true,
                token,
            };
        case actionTypes.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                loadingError: action.payload.error,
            };
        case actionTypes.GET_USER:
            const { userId, companyId } = action.payload;
            return {
                ...state,
                userId,
                companyId,
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                loggedIn: false,
                token: "",
            };
        case actionTypes.RESET_LOGIN_LOADER:
            return {
                loaded: false,
            };
        default:
            return state;
    }
};
