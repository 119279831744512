import React from "react";
import StyledBox from "./components/StyledBox";
import Logo from "../../components/Logo";
import BackgroundElements from "./components/Background";
import StyledPaper from "./components/StyledPaper";
import LeftSideBox from "./components/LeftSideBox";
import RightSideBox from "./components/RightSideBox";
import Blog from "./components/Blog";
import Form from "./components/Form";

const Login = () => {
    return (
        <StyledBox>
            <Logo withWrapper />
            <BackgroundElements />
            <StyledPaper>
                <LeftSideBox>
                    <Form />
                </LeftSideBox>
                {false && (
                    <RightSideBox>
                        <Blog />
                    </RightSideBox>
                )}
            </StyledPaper>
        </StyledBox>
    );
};

export default Login;
